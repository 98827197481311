import React from "react";
import {Blockquote, Box, Button, Flex, Grid, Heading, Spinner, Text} from "@radix-ui/themes";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import {setService, showBookingModal} from "../booking/bookingSlice";
import {useAppDispatch} from "../../store/hooks";
import {getQuantityLabelByServiceEnum, getServiceNameByEnum, Services} from "../../types/services";
import QRCode from "react-qr-code";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {useGetMyAccessZonesQuery} from "../ScanQR/scanInfoAPI";
import {formatDateToISO} from "../../helpers/dateFormat";
import {getPriceForCurrentMonth} from "../../helpers/prices";
import {getMonthDeclination} from "../../helpers/utils";

export default function TicketsScreen() {

    const dispatch = useAppDispatch();

    const { data: profile, isLoading: isLoadingProfile } = useGetProfileQuery(null);
    const {data: accessZones} = useGetMyAccessZonesQuery({
        date: formatDateToISO(new Date()),
    }, { pollingInterval: 5 * 60 * 1000}); // every 5min

    const prices = getPriceForCurrentMonth(Services.ENTRY_TICKET);

    const onBuyTicket = () => {
        dispatch(showBookingModal(true));
        dispatch(setService(Services.ENTRY_TICKET));
    }

    return (
        <Flex direction='column' pt='4'>
            <Heading>Вхідний білет</Heading>

            <Box mt='4'>
                <Button size='2' onClick={onBuyTicket}>
                    <PlusCircleIcon height='20px' width='20px' />
                    Придбати
                </Button>
            </Box>
            <Text size='1' color='gray' mt='2'>
                Аби потрапити у LAKE RESORT необхідно придбати вхідний білет. Зверніть увагу: білет надається безкоштовно при бронюванні/оплаті вейкбордингу, рибалки або будь-якого варіанту проживання
            </Text>

            <Box mt="2">
                {
                    prices.workDaySalePercentage ? (
                        <>

                            <Flex direction="column" gap="1" mt="2">
                                <Text size="2" weight="bold">Ціна: {prices.weekdayPrice}грн</Text>
                                <Text size='1'>у будні (пн-пт) знижка <b>-{prices.workDaySalePercentage}%</b></Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Text size="1" style={{fontStyle: 'italic'}}>ціни у {getMonthDeclination(prices.monthNameLocale)}</Text>
                            <Grid columns="2" gapX="2" gapY="1" style={{ gridTemplateColumns: "auto auto" }}>
                                <Text size='2'>вихідні:</Text>
                                <Text size='2' weight="bold">
                                    {prices.weekdayPrice}грн
                                </Text>
                                <Text size='2'>будні:</Text>
                                <Text size='2' weight="bold">
                                    {prices.workDayPrice}грн
                                </Text>
                            </Grid>
                        </>
                    )
                }
            </Box>

            <Box mt='6'>
                <Heading size='4'>Мій білет</Heading>

                { isLoadingProfile && <Spinner />}
                <Box mt='2'>
                    {
                        profile && (
                            <Box p="4" style={{ backgroundColor: '#fff' }}>
                                <QRCode
                                    value={JSON.stringify({userId: profile?.id})}
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                />
                            </Box>
                        )
                    }
                </Box>

                {
                    accessZones && (accessZones.reservations.length > 0) ? (
                        <Box mt="4">
                            <Text size="2" weight="bold">Активні бронювання на сьогодні</Text>
                            {
                                accessZones?.reservations.map(reservation => (
                                    <Blockquote key={reservation.id}>
                                        {getServiceNameByEnum(reservation.serviceId)} <br />
                                        Кількість заброньованих {getQuantityLabelByServiceEnum(reservation.serviceId)}: {reservation.numberOfSpots}
                                    </Blockquote>
                                ))
                            }
                        </Box>
                    ) : null
                }
            </Box>
        </Flex>
    )
}
