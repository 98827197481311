import {Services} from "../types/services";

export enum PriceType {
    WORK_DAY_PRICE,
    WEEKEND_PRICE
}

const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const weekdayPrices = [
    // June
    new Date(2024, 5, 1), // the month is 0-indexed
    new Date(2024, 5, 14),
    new Date(2024, 5, 15),
    new Date(2024, 5, 16),
    new Date(2024, 5, 17),
    new Date(2024, 5, 21),
    new Date(2024, 5, 22),
    new Date(2024, 5, 23),
    new Date(2024, 5, 24),
    new Date(2024, 5, 28),

    // July
    new Date(2024, 6, 5),
    new Date(2024, 6, 6),
    new Date(2024, 6, 7),
    new Date(2024, 6, 8),
    new Date(2024, 6, 13),
    new Date(2024, 6, 14),
    new Date(2024, 6, 15),
    new Date(2024, 6, 29),

    // August
    // new Date(2024, 7, 23),
    // new Date(2024, 7, 24),
    // new Date(2024, 7, 25),
    // new Date(2024, 7, 26),

    // September
    // new Date(2024, 8, 2),
]

// todo: include fridayAsWeekendPrice
export default function determinePriceTypeByDate(date: Date) {
    const day = weekday[date.getDay()];

    if (day === "Sunday" || day === "Saturday") return PriceType.WEEKEND_PRICE;

    const dateToCompare = date.setHours(0,0,0,0);

    return weekdayPrices.reduce((acc, date) => {
        if (date.getTime() === dateToCompare) return PriceType.WEEKEND_PRICE
        return acc;
    }, PriceType.WORK_DAY_PRICE)
}

export function getPriceForCurrentMonth(service?: Services) {
    if (! service) {
        return {
            monthNameLocale: '',
            workDayPrice: 0,
            weekdayPrice: 0,
            fridayAsWeekendPrice: false,
            workDaySalePercentage: undefined,
        }
    }

    const today = new Date();

    const month = today.toLocaleString('en-US', { month: "long" });

    return {
        monthNameLocale: today.toLocaleString('uk-UA', { month: "long" }),
        // @ts-expect-error
        workDayPrice: pricesByMonth[service][month][PriceType.WORK_DAY_PRICE],
        // @ts-expect-error
        weekdayPrice: pricesByMonth[service][month][PriceType.WEEKEND_PRICE],
        // @ts-expect-error
        fridayAsWeekendPrice: Boolean(pricesByMonth[service][month].fridayAsWeekendPrice),
        // @ts-expect-error
        workDaySalePercentage: pricesByMonth[service][month].workDaySalePercentage ?? undefined,
    }
}

export const pricesByMonth = {
    [Services.ENTRY_TICKET]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 600,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 600,
            workDaySalePercentage: 50,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 201,
            [PriceType.WEEKEND_PRICE]: 300,
            workDaySalePercentage: 33,
        },
    },
    [Services.SUN_LOUNGERS_WITH_TABLE]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 400,
            [PriceType.WEEKEND_PRICE]: 550,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 500,
            [PriceType.WEEKEND_PRICE]: 550,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 500,
            [PriceType.WEEKEND_PRICE]: 550,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 500,
        },
    },
    [Services.SUN_LOUNGERS]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 250,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 150,
            [PriceType.WEEKEND_PRICE]: 300,
            workDaySalePercentage: 50,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 150,
            [PriceType.WEEKEND_PRICE]: 300,
            workDaySalePercentage: 50,
        },
    },
    [Services.WAKEBOARDING]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 900,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 900,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 900,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 900,
        },
    },
    [Services.MINIPOOL]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 600,
            [PriceType.WEEKEND_PRICE]: 800,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 400,
            [PriceType.WEEKEND_PRICE]: 800,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 402,
            [PriceType.WEEKEND_PRICE]: 600,
            workDaySalePercentage: 33,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 402,
            [PriceType.WEEKEND_PRICE]: 600,
            workDaySalePercentage: 33,
        },
    },
    [Services.GLAMPING_STANDARD]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
            fridayAsWeekendPrice: true,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2500,
            fridayAsWeekendPrice: true,
        },
    },
    [Services.GLAMPING_COMFORT]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 3000,
            [PriceType.WEEKEND_PRICE]: 4000,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 3500,
            [PriceType.WEEKEND_PRICE]: 5000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 4000,
            [PriceType.WEEKEND_PRICE]: 5000,
            fridayAsWeekendPrice: true,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 4000,
            [PriceType.WEEKEND_PRICE]: 5000,
            fridayAsWeekendPrice: true,
        },
    },
    [Services.GLAMPING_STANDARD_BIG]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 2500,
            [PriceType.WEEKEND_PRICE]: 3500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 2500,
            [PriceType.WEEKEND_PRICE]: 3500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 2500,
            [PriceType.WEEKEND_PRICE]: 3500,
            fridayAsWeekendPrice: true,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 2500,
            [PriceType.WEEKEND_PRICE]: 3500,
            fridayAsWeekendPrice: true,
        },
    },
    [Services.CAMPING_STANDARD]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 1400,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 800,
            [PriceType.WEEKEND_PRICE]: 1400,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 663,
            [PriceType.WEEKEND_PRICE]: 990,
            workDaySalePercentage: 33,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 663,
            [PriceType.WEEKEND_PRICE]: 990,
            workDaySalePercentage: 33,
        },
    },
    [Services.CAMPING_COMFORT]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 2300,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1340,
            [PriceType.WEEKEND_PRICE]: 2000,
            workDaySalePercentage: 33,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1340,
            [PriceType.WEEKEND_PRICE]: 2000,
            workDaySalePercentage: 33,
        },
    },
    [Services.HOTEL_THERMAL_LAKE]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1200,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
    },
    [Services.HOTEL_THERMAL_FOREST]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1200,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
    },
    [Services.AHOME_THREE_STANDARD]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 3500,
            [PriceType.WEEKEND_PRICE]: 4500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 4500,
            [PriceType.WEEKEND_PRICE]: 5500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 3500,
            [PriceType.WEEKEND_PRICE]: 4500,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 3500,
            [PriceType.WEEKEND_PRICE]: 4500,
        },
    },
    [Services.AHOME_THREE_COMFORT]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 5500,
            [PriceType.WEEKEND_PRICE]: 6500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 6500,
            [PriceType.WEEKEND_PRICE]: 7500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 5500,
            [PriceType.WEEKEND_PRICE]: 6500,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 5500,
            [PriceType.WEEKEND_PRICE]: 6500,
        },
    },
    [Services.AHOME_SIX_STANDARD]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 5000,
            [PriceType.WEEKEND_PRICE]: 6000,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 6000,
            [PriceType.WEEKEND_PRICE]: 7000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 6000,
            [PriceType.WEEKEND_PRICE]: 7000,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 5000,
            [PriceType.WEEKEND_PRICE]: 6000,
        },
    },
    [Services.AHOME_SIX_COMFORT]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 7000,
            [PriceType.WEEKEND_PRICE]: 8000,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 8000,
            [PriceType.WEEKEND_PRICE]: 9000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 8000,
            [PriceType.WEEKEND_PRICE]: 9000,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 7000,
            [PriceType.WEEKEND_PRICE]: 8000,
        },
    },
    [Services.BATH_HOUSE]: { // чани
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1500,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
    },
    [Services.SAUNA]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1500,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1000,
        },
    },
    [Services.BATH_HOUSE_AND_SAUNA]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 2000,
            [PriceType.WEEKEND_PRICE]: 2500,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 2000,
            [PriceType.WEEKEND_PRICE]: 2500,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 2000,
            [PriceType.WEEKEND_PRICE]: 2500,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 2000,
            [PriceType.WEEKEND_PRICE]: 2000,
        },
    },
    [Services.SAP]: { // сап 1 год
        "June": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
    },
    [Services.KAYAK]: { // каяк 1 год
        "June": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 200,
        },
    },
    [Services.BOAT]: { // човен 1 год
        "June": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 250,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 200,
            [PriceType.WEEKEND_PRICE]: 250,
        },
    },
    [Services.CATAMARAN]: { // 1 год
        "June": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 300,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 300,
            [PriceType.WEEKEND_PRICE]: 300,
        },
    },
    [Services.HORSE_RIDE]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1000,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1000,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1000,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 1000,
            [PriceType.WEEKEND_PRICE]: 1000,
        },
    },
    [Services.FISHING]: {
        "June": {
            [PriceType.WORK_DAY_PRICE]: 450,
            [PriceType.WEEKEND_PRICE]: 650,
        },
        "July": {
            [PriceType.WORK_DAY_PRICE]: 500,
            [PriceType.WEEKEND_PRICE]: 700,
        },
        "August": {
            [PriceType.WORK_DAY_PRICE]: 469,
            [PriceType.WEEKEND_PRICE]: 700,
            workDaySalePercentage: 33,
        },
        "September": {
            [PriceType.WORK_DAY_PRICE]: 455,
            [PriceType.WEEKEND_PRICE]: 650,
            workDaySalePercentage: 30,
        },
    },
}
